import React,{useContext} from "react";
import { Container, Row, Col } from "react-bootstrap";

// services
import { DataContext } from '../../DataContext';
import Particle from "../Particle";
import './Services.css';
// Import images
import web from '../../Assets/Services/web.png';
import software from '../../Assets/Services/software.png';
import mobile from '../../Assets/Services/mobile.png';
import digitalCloud from '../../Assets/Services/digital_cloud.png';
import itConsultant from '../../Assets/Services/it_consulting.png';
import networkManagement from '../../Assets/Services/network_management.png';
import helpdesk from '../../Assets/Services/help_desk.png';
import dataBackup from '../../Assets/Services/data_backup.png';

// Map filenames to imported images
const imageMap = {
    'web.png': web,
    'software.png': software,
    'mobile.png': mobile,
    'digital_cloud.png': digitalCloud,
    'it_consulting.png': itConsultant,
    'data_backup.png': dataBackup,
    'network_management.png': networkManagement,
    'help_desk.png': helpdesk,
};

function Services(){

    const { data, error, loading } = useContext(DataContext);
    // Show a loading state while data is being fetched
    if (loading) {
        return <div>Loading...</div>; // You can customize this loading UI
    }

    // Handle error state (optional)
    if (error) {
        return <div>Error loading data</div>; // Customize this as needed
    }

    return(
        <Container fluid className="services-section">
            <Particle />
            <Container>
                <Row className="justify-content-center mb-4">
                    <Col xs={12} className="text-center">
                        <h2 className="section-name">Our Services</h2>
                        <p className="section-subtitle">Explore the range of services we offer to help you succeed</p>
                    </Col>
                </Row>

                {data.company.services.map((service, index) => (
                    <Row className={`service-row ${index % 2 === 0 ? 'reverse' : ''}`}>
                        <Col md={6} className="service-image-col">
                            <img src={imageMap[service.image]}  alt={service.name} className="service-image" />
                        </Col>
                        <Col md={6} className="service-text-col">
                            <h3 className="service-name">{service.name}</h3>
                            <p className="service-description">{service.description}</p>
                        </Col>
                    </Row>
                ))}
            </Container>
        </Container>
    );
}
export default Services;