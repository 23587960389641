import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard({title,content}) {
  return (
    <Card className="quote-card-view">
      <Card.Body>
       <p style={{ color: "rgb(155 126 172)" }}>
            {title}
          </p>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          
           {content}
          </p>
        

         
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
