import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about_us.png";


function About() {
  return (
    <Container fluid className="about-section">
 
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Discover Who We <strong className="purple">ARE</strong>
            </h1>
            <Aboutcard 
              title="Our Expertise"
              content="We are a dedicated IT company specializing in app development, web development, and SEO services. Our team of experts leverages the latest technologies and industry best practices to deliver innovative solutions tailored to meet your business needs."
            />
            <Aboutcard 
              title="Our Mission"
              content="Our mission is to empower businesses with cutting-edge technology solutions that drive growth and enhance online presence. We strive to create seamless user experiences and optimize visibility through effective SEO strategies."
            />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        
      
  
      </Container>
    </Container>
  );
}

export default About;
