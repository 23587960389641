import React,{useContext} from "react";
import Typewriter from "typewriter-effect";
import { DataContext } from '../../DataContext';

function Type() {

    const { data, error, loading } = useContext(DataContext);
  // Show a loading state while data is being fetched
  if (loading) {
    return <div>Loading...</div>; // You can customize this loading UI
  }

  // Handle error state (optional)
  if (error) {
    return <div>Error loading data</div>; // Customize this as needed
  }

  return (
    <Typewriter
    style={{ color: 'blue', fontSize: '24px' }} // Change color and other styles here

      options={{
        strings: data.bannertext,
        autoStart: true,
        loop: true,
         delay: 10,
         pauseFor:3000,
        deleteSpeed: 10,

      }}
    />
  );
}

export default Type;
