// src/DataContext.js
import React, { createContext, useEffect, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data.json');
        if (!response.ok) throw new Error('Network response was not ok');
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false)
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  return (
    <DataContext.Provider value={{ data, error,loading }}>
      {children}
    </DataContext.Provider>
  );
};
