import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillFacebook
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            
             <li className="social-icons">
              <a
                href="https://www.facebook.com/people/Scriptiq/61563304897101"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillFacebook />
              </a>
            </li>
          </ul>
        </Col>
        <Col md="2" className="footer-copywright">
          <h3></h3>
        </Col>
        <Col md="6" className="footer-copywright">
          <h3> ©{year} Script IQ Enterprise. 202403105620 (RA0111812-W) | All rights reserved.</h3>
        </Col>
      
      </Row>
    </Container>
  );
}

export default Footer;
