import React,{useContext} from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
// projects
import { DataContext } from '../../DataContext';

function Projects() {

    const { data, error, loading } = useContext(DataContext);
  // Show a loading state while data is being fetched
  if (loading) {
    return <div>Loading...</div>; // You can customize this loading UI
  }

  // Handle error state (optional)
  if (error) {
    return <div>Error loading data</div>; // Customize this as needed
  }


  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Discover Our <strong className="purple">Works </strong>
        </h1>
       
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          
        {data.projects.map((project, index) => (
        <Col md={4} className="project-card" key={index}>
          <ProjectCard
            imgPath={project.imgPath}
            isBlog={project.isBlog}
            title={project.title}
            description={project.description}
            ghLink={project.ghLink}
            demoLink={project.demoLink}
          />
        </Col>
      ))}

          
         

       

        

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
